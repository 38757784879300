import { FC } from "react"
import { Spinner as SpinnerIcon } from "app/assets/svg"
import { SpinnerProps } from "./SpinnerProps"
import "./Spinner.scss"

const Spinner: FC<SpinnerProps> = ({ height }) => {
  return (
    <div className="spinner" data-testid="spinner">
      <SpinnerIcon height={height} />
    </div>
  )
}

export default Spinner
