import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { NotFoundProps } from "./NotFoundProps"
import { FullLogo, NotFoundIcon } from "app/assets/svg"
import Button from "../Button/Button"
import "./NotFound.scss"

const NotFound: FC<NotFoundProps> = ({ title }) => {
  const navigate = useNavigate()

  return (
    <main className="not_found">
      <FullLogo />
      <div className="not_found_content">
        <div className="not_found_content_action">
          <h1>Ooops!</h1>
          <h2>{title}</h2>
          <Button
            text="Go back home"
            classType="primary"
            onClick={() => navigate("/")}
            otherClassName="home_btn"
          />
        </div>
        <NotFoundIcon />
      </div>
    </main>
  )
}

export default NotFound
