import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { profileBoost } from "app/api"
import { dataStages } from "app/helpers/enum"

const { LOADING, DATA, ERROR } = dataStages

export type ProfileBoostType = {
  userId: string
  targetCountry: string
  targetGender: string
  targetCity: string
  minAge: number
  maxAge: number
  targetNumber: number
  duration: number
  adsCost: number
  profileUrlForAds: string
  startDate: Date
  endDate: Date
  userProfileUrl: string
}

export type ProfileBoostInitialState = {
  profileBoost: {
    profileBoostList: {
      pages: {
        [key: number]: ProfileBoostType[]
      }
      totalRecords: number
    }
    profileBoostParams: {
      PageNumber: number
      PageSize: number
      isActive: boolean
    }
    searchValue: string
    stage: dataStages
  }
}

export const initialState: ProfileBoostInitialState = {
  profileBoost: {
    profileBoostList: {
      pages: {},
      totalRecords: 0,
    },
    profileBoostParams: {
      PageNumber: 1,
      PageSize: 10,
      isActive: true,
    },
    searchValue: "",
    stage: LOADING,
  },
}

export const profileBoostRequest = createAsyncThunk<
  {
    data: ProfileBoostInitialState["profileBoost"]["profileBoostList"]["pages"]
    totalRecords: ProfileBoostInitialState["profileBoost"]["profileBoostList"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; isActive: boolean }
>(
  "profileBoosts",
  async ({ PageNumber, PageSize, isActive }, { rejectWithValue }) => {
    try {
      const response = await profileBoost.getProfileBoosts(
        PageNumber,
        PageSize,
        isActive,
      )

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const profileBoostSlice = createSlice({
  name: "profileBoosts",
  initialState,
  reducers: {
    setProfileBoostParams: (state, { payload }) => {
      return {
        ...state,
        profileBoost: {
          ...state.profileBoost,
          profileBoostParams: {
            ...state.profileBoost.profileBoostParams,
            ...payload,
          },
        },
      }
    },
    clearProfileBoostList: (state, { payload }) => {
      return {
        ...state,
        profileBoost: {
          ...state.profileBoost,
          profileBoostList: {
            ...state.profileBoost.profileBoostList,
            pages: {},
          },
          profileBoostParams: {
            ...state.profileBoost.profileBoostParams,
            PageNumber: 1,
            isActive: payload?.isActive,
          },
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileBoostRequest.pending, (state) => {
      state.profileBoost.stage = LOADING
    })
    builder.addCase(profileBoostRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        profileBoost: {
          ...state.profileBoost,
          profileBoostList: {
            pages: {
              ...state.profileBoost.profileBoostList.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(profileBoostRequest.rejected, (state) => {
      state.profileBoost.stage = ERROR
    })
  },
})

export const { setProfileBoostParams, clearProfileBoostList } =
  profileBoostSlice.actions

export default profileBoostSlice.reducer
