import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { userSubscriptions, users } from "app/api"
import { toast } from "react-hot-toast"
import { dataStages } from "app/helpers/enum"
import { UsersType } from "features/Users/UsersSlice"

const { LOADING, DATA, ERROR } = dataStages

export type UserSubscriptionType = {
  userId: string
  subscriptionId: number
  amount: number
  durationInMonths: number
}

export type SubscriptionsInitialState = {
  subscriptions: {
    subscribersCount: {
      regularUsersCount: number
      silverUsersCount: number
      goldUsersCount: number
    }
    stage: dataStages
  }
  subscribers: {
    subscribersList: {
      pages: {
        [key: number]: UsersType[]
      }
      totalRecords: number
    }
    subscribersParams: {
      PageNumber: number
      PageSize: number
    }
    searchValue: string
    stage: dataStages
  }
}

export const initialState: SubscriptionsInitialState = {
  subscriptions: {
    subscribersCount: {
      regularUsersCount: 0,
      silverUsersCount: 0,
      goldUsersCount: 0,
    },
    stage: LOADING,
  },
  subscribers: {
    subscribersList: { pages: {}, totalRecords: 0 },
    subscribersParams: {
      PageNumber: 1,
      PageSize: 10,
    },
    searchValue: "",
    stage: LOADING,
  },
}

export const subscribersCountRequest = createAsyncThunk<
  SubscriptionsInitialState["subscriptions"]["subscribersCount"],
  void
>("subscriptions/subscribers", async (_, { rejectWithValue }) => {
  try {
    const response = await userSubscriptions.getSubscribersCount()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const subscribersRequest = createAsyncThunk<
  {
    data: SubscriptionsInitialState["subscribers"]["subscribersList"]["pages"]
    totalRecords: SubscriptionsInitialState["subscribers"]["subscribersList"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; searchValue?: string }
>(
  "subscriptions/subscribers-list",
  async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
    try {
      const response = searchValue
        ? await users.usersSearch(PageNumber, PageSize, searchValue)
        : await users.getUsers(PageNumber, PageSize)

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateUserSubscriptionRequest = createAsyncThunk<
  "",
  UserSubscriptionType
>(
  "subscriptions/update-user-subscription",
  async (values, { rejectWithValue }) => {
    try {
      await userSubscriptions.updateUserSubscription(values)

      toast.success("User updated successfully")
      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscribersParams: (state, { payload }) => {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          subscribersParams: {
            ...state.subscribers.subscribersParams,
            PageNumber: payload,
          },
        },
      }
    },
    setSubscribersSearchValue: (state, { payload }) => {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          searchValue: payload,
        },
      }
    },
    clearSubscribersList: (state) => {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          searchValue: "",
          subscribersList: {
            ...state.subscribers.subscribersList,
            pages: {},
          },
          subscribersParams: {
            ...state.subscribers.subscribersParams,
            PageNumber: 1,
          },
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscribersCountRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          subscribersCount: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(subscribersCountRequest.rejected, (state) => {
      state.subscriptions.stage = ERROR
    })
    builder.addCase(subscribersRequest.pending, (state) => {
      state.subscribers.stage = LOADING
    })
    builder.addCase(subscribersRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          subscribersList: {
            pages: {
              ...state.subscribers.subscribersList.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(subscribersRequest.rejected, (state) => {
      state.subscribers.stage = ERROR
    })
  },
})

export const {
  setSubscribersParams,
  setSubscribersSearchValue,
  clearSubscribersList,
} = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
