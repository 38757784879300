import axios, { AxiosError, AxiosResponse, GenericAbortSignal } from "axios"
import { store } from "../store"
import {
  LoginType,
  ResetPasswordType,
  ChangePasswordType,
} from "features/Auth/AuthSlice"
import { InviteStaffsType } from "features/Staffs/StaffsSlice"
import {
  NearbySettingsType,
  ChargesType,
  SharingRateType,
  InterestType,
} from "features/Settings/SettingsSlice"
import { SubFeaturesType } from "features/SubscriptionFeatures/SubscriptionFeaturesSlice"
import { UserProfileType } from "features/Users/UsersSlice"
import { UserSubscriptionType } from "features/Subscriptions/SubscriptionsSlice"
import { handleError } from "app/helpers"

export const url = "https://test-api.zheeta.com/api/"

axios.defaults.baseURL = url

export const responseBody = (response: AxiosResponse) => response.data

axios.interceptors.request.use((config) => {
  const token = store.getState().auth?.token
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

axios.interceptors.response.use(
  async (response) => {
    return response
  },
  (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse

    handleError(status, data.Message)

    return Promise.reject(error.response)
  },
)

const requests = {
  get: (url: string, signal?: GenericAbortSignal, params?: URLSearchParams) =>
    axios.get(url, { params, signal }).then(responseBody),
  post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body?: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string, body?: {}) =>
    axios.delete(url, body).then(responseBody),
}

export const auth = {
  login: (values: LoginType) => requests.post("userauth/login", values),
  forgotPassword: (email: string) =>
    requests.post(`userauth/send-password-reset-otp-to-email/${email}`),
  resetPassword: (values: ResetPasswordType) =>
    requests.put("userauth/reset-password", values),
  changePassword: (values: ChangePasswordType) =>
    requests.post("userauth/change-password", values),
}

export const staffs = {
  getStaffs: (PageNumber: number, PageSize: number) =>
    requests.get(
      `user/get-all-users?PageNumber=${PageNumber}&PageSize=${PageSize}&roleType=2`,
    ),
  staffsSearch: (PageNumber: number, PageSize: number, searchValue: string) =>
    requests.get(
      `user/find-user-by-admin?username=${searchValue}&PageNumber=${PageNumber}&PageSize=${PageSize}`,
    ),
  invite: (values: Omit<InviteStaffsType, "id">) =>
    requests.post("userauth/register-staff", values),
  updateStaff: (values: InviteStaffsType) =>
    requests.put("userauth/update-staff", values),
}

export const gifts = {
  getGifts: (PageNumber: number, PageSize: number) => requests.get(`gift/get-all-gifts?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  addGift: (formData: FormData) => requests.post("gift/create-gift", formData),
  updateGift: (formData: FormData) => requests.put("gift/update-gift", formData),
  deleteGift: (id: string) => requests.delete(`gift/delete-gift/${id}`)
}


export const insight = {
  getZheetaWalletBalance: () => requests.get("user/zheeta-wallet"),
  getTotalWalletBalance: () =>
    requests.get("admin-settings/get-total-wallet-amount"),
  registeredUsers: (year: string) =>
    requests.get(`user/get-registered-userCount-byMonth?year=${year}`),
  usersCountry: () => requests.get("user/country-counters"),
}

export const settings = {
  getInterests: () => requests.get("admin-settings/interests"),
  addInterest: (values: Omit<InterestType, "id">) =>
    requests.post("admin-settings/interest", values),
  updateInterest: (values: InterestType) =>
    requests.post("admin-settings/interest", values),
  deleteInterest: (id: number) =>
    requests.post(`admin-settings/interest/${id}`),
  getCharges: () => requests.get("admin-settings/charges"),
  updateCharges: (
    values: Omit<ChargesType, "createdDate" | "lastModifiedDate">,
  ) => requests.put("admin-settings/update-charge", values),
  getSharingRate: () => requests.get("admin-settings/sharing-rates"),
  updateSharingRate: (values: SharingRateType) =>
    requests.put("admin-settings/update-sharing-rate", values),
  getNearBySettings: () => requests.get("admin-settings/nearbysettings"),
  updateNearBySettings: (values: NearbySettingsType) =>
    requests.put("admin-settings/update-nearbysettings", values),
}

export const subscriptionFeatures = {
  getSubFeatures: () => requests.get("admin-settings/subscriptions"),
  updateSubFeature: (values: SubFeaturesType) =>
    requests.put("admin-settings/update-subscription", values),
  getPaymentTypes: () => requests.get("user/payment-types"),
}

export const users = {
  getUsers: (PageNumber: number, PageSize: number) =>
    requests.get(
      `user/get-all-users?PageNumber=${PageNumber}&PageSize=${PageSize}&roleType=1`,
    ),
  getSingleUser: (userId: string) =>
    requests.get(`user/get-single-user-profile?userId=${userId}`),
  usersSearch: (PageNumber: number, PageSize: number, searchValue: string) =>
    requests.get(
      `user/find-user-by-admin?username=${searchValue}&PageNumber=${PageNumber}&PageSize=${PageSize}`,
    ),
  updateUserProfile: (values: UserProfileType) =>
    requests.put("user/update-user-profile", values),
  getRolesCount: () => requests.get(`user/roles/count`),
  enableUser: (userId: string) =>
    requests.put(`user/enable-user-account/${userId}`),
  disableUser: (userId: string) =>
    requests.put(`user/disable-user-account/${userId}`),
}

export const paymentHistory = {
  getPaymentHistory: (PageNumber: number, PageSize: number) =>
    requests.get(
      `payment/get-all-pay-in-history?PageNumber=${PageNumber}&PageSize=${PageSize}`,
    ),
}

export const transactions = {
  getTransactions: (PageNumber: number, PageSize: number) =>
    requests.get(
      `transaction/get-all-transactions?PageNumber=${PageNumber}&PageSize=${PageSize}`,
    ),
}

export const userSubscriptions = {
  getSubscribersCount: () => requests.get("user/subscriptions/count"),
  updateUserSubscription: (values: UserSubscriptionType) =>
    requests.put("user/update-user-subscription", values),
}

export const profileBoost = {
  getProfileBoosts: (PageNumber: number, PageSize: number, isActive: boolean) =>
    requests.get(
      `profile-boost/get-all-admin?PageNumber=${PageNumber}&PageSize=${PageSize}&isActive=${isActive}`,
    ),
}
