import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { settings } from "app/api"
import { dataStages } from "app/helpers/enum"
import { handleResponse } from "app/helpers"

const { LOADING, DATA, ERROR } = dataStages

type DefaultResponse = {
  statusCode: number
  message: string
}

export type InterestType = {
  id: number
  title: string
}

export type ChargesType = {
  id: number
  title: string
  amount: number
  createdDate: Date
  lastModifiedDate: Date
}

export type SharingRateType = {
  id: number
  referralRate: number
  type: string
  userRate: number
  zheetaRate: number
}

export type NearbySettingsType = {
  id: number
  radiusInKm: number
}

export type SettingsInitialState = {
  interests: {
    interestList: InterestType[]
    stage: dataStages
  }
  charges: {
    chargesList: ChargesType[]
    stage: dataStages
  }
  sharingRates: {
    rates: SharingRateType[]
    stage: dataStages
  }
  nearbySettings: {
    nearby: NearbySettingsType
    stage: dataStages
  }
}

const initialState: SettingsInitialState = {
  interests: {
    interestList: [],
    stage: LOADING,
  },
  charges: {
    chargesList: [],
    stage: LOADING,
  },
  sharingRates: {
    rates: [],
    stage: LOADING,
  },
  nearbySettings: {
    nearby: {
      id: 0,
      radiusInKm: 0,
    },
    stage: LOADING,
  },
}

export const interestsRequest = createAsyncThunk<
  SettingsInitialState["interests"]["interestList"],
  void
>("settings/interests", async (_, { rejectWithValue }) => {
  try {
    const response = await settings.getInterests()

    return response?.data?.sort(
      (a: InterestType, b: InterestType) => a?.id - b?.id,
    )
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const addInterestsRequest = createAsyncThunk<
  DefaultResponse,
  Omit<InterestType, "id">
>("settings/add-interest", async (values, { rejectWithValue }) => {
  try {
    const response = await settings.addInterest(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateInterestsRequest = createAsyncThunk<
  DefaultResponse,
  InterestType
>("settings/update-interest", async (values, { rejectWithValue }) => {
  try {
    const response = await settings.updateInterest(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const deleteInterestsRequest = createAsyncThunk<DefaultResponse, number>(
  "settings/delete-interest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await settings.deleteInterest(id)

      return {
        statusCode: response?.statusCode,
        message: response?.message,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const chargesRequest = createAsyncThunk<
  SettingsInitialState["charges"]["chargesList"],
  void
>("settings/charges", async (_, { rejectWithValue }) => {
  try {
    const response = await settings.getCharges()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateChargesRequest = createAsyncThunk<
  DefaultResponse,
  Omit<ChargesType, "createdDate" | "lastModifiedDate">
>("settings/update-charges", async (values, { rejectWithValue }) => {
  try {
    const response = await settings.updateCharges(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const sharingRateRequest = createAsyncThunk<
  SettingsInitialState["sharingRates"]["rates"],
  void
>("settings/sharing-rate", async (_, { rejectWithValue }) => {
  try {
    const response = await settings.getSharingRate()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateSharingRateRequest = createAsyncThunk<
  DefaultResponse,
  SharingRateType
>("settings/update-sharing-rate", async (values, { rejectWithValue }) => {
  try {
    const response = await settings.updateSharingRate(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const nearbySettingRequest = createAsyncThunk<
  SettingsInitialState["nearbySettings"]["nearby"],
  void
>("settings/nearby-settings", async (_, { rejectWithValue }) => {
  try {
    const response = await settings.getNearBySettings()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateNearbySettingRequest = createAsyncThunk<
  DefaultResponse,
  NearbySettingsType
>("settings/update-nearby-settings", async (values, { rejectWithValue }) => {
  try {
    const response = await settings.updateNearBySettings(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(interestsRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        interests: {
          ...state.interests,
          interestList: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(interestsRequest.rejected, (state) => {
      state.interests.stage = ERROR
    })
    builder.addCase(addInterestsRequest.fulfilled, (state, { payload }) => {
      handleResponse(
        payload.statusCode,
        "Interest added successfully",
        payload.message,
      )
    })
    builder.addCase(updateInterestsRequest.fulfilled, (state, { payload }) => {
      handleResponse(
        payload.statusCode,
        "Interest updated successfully",
        payload.message,
      )
    })
    builder.addCase(deleteInterestsRequest.fulfilled, (state, { payload }) => {
      handleResponse(
        payload.statusCode,
        "Interest deleted successfully",
        payload.message,
      )
    })
    builder.addCase(chargesRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        charges: {
          ...state.charges,
          chargesList: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(chargesRequest.rejected, (state, { payload }) => {
      state.charges.stage = ERROR
    })
    builder.addCase(updateChargesRequest.fulfilled, (state, { payload }) => {
      handleResponse(
        payload.statusCode,
        "Charges updated successfully",
        payload.message,
      )
    })
    builder.addCase(sharingRateRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        sharingRates: {
          ...state.sharingRates,
          rates: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(sharingRateRequest.rejected, (state) => {
      state.sharingRates.stage = ERROR
    })
    builder.addCase(
      updateSharingRateRequest.fulfilled,
      (state, { payload }) => {
        handleResponse(
          payload.statusCode,
          "Sharing rate updated successfully",
          payload.message,
        )
      },
    )
    builder.addCase(nearbySettingRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        nearbySettings: {
          ...state.nearbySettings,
          nearby: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(nearbySettingRequest.rejected, (state) => {
      state.nearbySettings.stage = ERROR
    })
    builder.addCase(
      updateNearbySettingRequest.fulfilled,
      (state, { payload }) => {
        handleResponse(
          payload.statusCode,
          "Nearby setting updated successfully",
          payload.message,
        )
      },
    )
  },
})

export default settingsSlice.reducer
