import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { auth } from "app/api"
import { toast } from "react-hot-toast"
import router from "app/route"
import { handleError } from "app/helpers"

export type LoginType = {
  email: string
  password: string
  isAdmin: boolean
}

export type ResetPasswordType = {
  email: string
  otp: string
  newPassword: string
}

export type ChangePasswordType = {
  oldPassword: string
  newPassword: string
}

export type AuthInitialState = {
  token: string
  username: string
}

const initialState: AuthInitialState = {
  token: "",
  username: "",
}

export const loginRequest = createAsyncThunk<
  {
    data: AuthInitialState
    statusCode: number
    message: string
  },
  LoginType
>("auth/login", async (values, { rejectWithValue }) => {
  try {
    const response = await auth.login(values)

    return {
      data: response?.data,
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const forgotPasswordRequest = createAsyncThunk<
  { email: string; statusCode: number; message: string },
  string
>("auth/forgot-password", async (email, { rejectWithValue }) => {
  try {
    const response = await auth.forgotPassword(email)

    return {
      email,
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const resetPasswordRequest = createAsyncThunk<
  {
    statusCode: number
    message: string
  },
  ResetPasswordType
>("auth/reset-password", async (values, { rejectWithValue }) => {
  try {
    const response = await auth.resetPassword(values)

    return {
      statusCode: response?.statusCode,
      message: response?.message,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const changePasswordRequest = createAsyncThunk<
  {
    statusCode: number
    message: string
  },
  ChangePasswordType
>("auth/change-password", async (values, { rejectWithValue }) => {
  try {
    const response = await auth.changePassword(values)

    return {
      statusCode: response?.statusCode,
      message: response?.data?.[0] || "",
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.token = ""
      state.username = ""
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.fulfilled, (state, { payload }) => {
      if (payload.statusCode === 201) {
        state.token = payload?.data?.token
        state.username = payload?.data?.username
        router.navigate(
          router?.state?.location?.state?.from?.pathname || "/admin/insight",
        )
      } else {
        handleError(payload.statusCode, payload.message)
      }
    })
    builder.addCase(forgotPasswordRequest.fulfilled, (state, { payload }) => {
      if (payload.statusCode === 201) {
        toast.success("Password reset OTP sent successfully")
        router.navigate("/auth/reset-password", {
          state: { email: payload?.email },
        })
      } else {
        handleError(payload.statusCode, payload.message)
      }
    })
    builder.addCase(resetPasswordRequest.fulfilled, (state, { payload }) => {
      if (payload.statusCode === 201) {
        toast.success("Password reset successful")
        router.navigate("/auth")
      } else {
        handleError(payload.statusCode, payload.message)
      }
    })
    builder.addCase(changePasswordRequest.fulfilled, (state, { payload }) => {
      if (payload.statusCode === 201) {
        toast.success("Password changed successfully")
      } else {
        handleError(payload.statusCode, payload.message)
      }
    })
  },
})

export const { logOut } = authSlice.actions

export default authSlice.reducer
