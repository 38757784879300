import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { gifts } from "app/api"
import { dataStages } from "app/helpers/enum"
import toast from "react-hot-toast"
import { createGiftFormData, updateGiftFormData } from "./utils/giftsFormData"

const { LOADING, DATA, ERROR } = dataStages

type DefaultResponse = {
    statusCode: number
    message: string
}

export type GiftsType = {
    imageUrl: string
    title: string
    amount: number
    id?: string
    partitionKey?: string
    lastModifiedDate?: string
    createdDate?: string
}

export type GiftsInitialState = {
    gifts: {
        giftsList: {
            pages: {
                [key: number]: GiftsType[]
            }
            totalRecords: number
        }
        giftsParams: {
            PageNumber: number
            PageSize: number
        }
        searchValue: string
        stage: dataStages
    }
}

export const initialState: GiftsInitialState = {
    gifts: {
        giftsList: {
            pages: {},
            totalRecords: 0,
        },
        giftsParams: {
            PageNumber: 1,
            PageSize: 10,
        },
        searchValue: "",
        stage: LOADING,
    },
}

export const giftsRequest = createAsyncThunk<
    {
        data: GiftsInitialState["gifts"]["giftsList"]["pages"]
        totalRecords: GiftsInitialState["gifts"]["giftsList"]["totalRecords"]
    },
    { PageNumber: number; PageSize: number; searchValue?: string }
>(
    "gifts",
    async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
        try {
            const response = await gifts.getGifts(PageNumber, PageSize)

            return {
                data: { [PageNumber]: response.data },
                totalRecords: response.totalCount,
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

export const createGiftRequest = createAsyncThunk<"", GiftsType>(
    "gift/create",
    async (values, { rejectWithValue }) => {
        try {
            const addGiftFormData = createGiftFormData(values)

            await gifts.addGift(addGiftFormData)

            toast.success("Gift added successfully")
            return ""
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

export const updateGiftRequest = createAsyncThunk<"", GiftsType>(
    "gift/update",
    async (values, { rejectWithValue }) => {
        try {

            const updateGiftForm = updateGiftFormData(values)

            await gifts.updateGift(updateGiftForm)

            toast.success("Gift updated successfully")
            return ""
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

export const deleteGiftRequest = createAsyncThunk<DefaultResponse, string>(
    "gifts/delete",
    async (id, { rejectWithValue }) => {
        try {
            const response = await gifts.deleteGift(id)

            return {
                statusCode: response?.statusCode,
                message: response?.message,
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

export const giftsSlice = createSlice({
    name: "gifts",
    initialState,
    reducers: {
        setGiftsParams: (state, { payload }) => {
            return {
                ...state,
                gifts: {
                    ...state.gifts,
                    giftsParams: {
                        ...state.gifts.giftsParams,
                        PageNumber: payload,
                    },
                },
            }
        },
        setSearchValue: (state, { payload }) => {
            return {
                ...state,
                gifts: {
                    ...state.gifts,
                    searchValue: payload,
                },
            }
        },
        clearGiftsList: (state) => {
            return {
                ...state,
                gifts: {
                    ...state.gifts,
                    searchValue: "",
                    giftsList: {
                        ...state.gifts.giftsList,
                        pages: {},
                    },
                    giftsParams: {
                        ...state.gifts.giftsParams,
                        PageNumber: 1,
                    }
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(giftsRequest.pending, (state, { payload }) => {
            state.gifts.stage = LOADING
        })
        builder.addCase(giftsRequest.fulfilled, (state, { payload }) => {
            return {
                ...state,
                gifts: {
                    ...state.gifts,
                    giftsList: {
                        pages: {
                            ...state.gifts.giftsList.pages,
                            ...payload.data,
                        },
                        totalRecords: payload.totalRecords,
                    },
                    stage: DATA,
                },
            }
        })
        builder.addCase(giftsRequest.rejected, (state) => {
            state.gifts.stage = ERROR
        })

        builder.addCase(createGiftRequest.pending, (state) => {
            state.gifts.stage = LOADING;
        });
        builder.addCase(createGiftRequest.fulfilled, (state) => {
            state.gifts.stage = DATA;
        });
        builder.addCase(createGiftRequest.rejected, (state) => {
            state.gifts.stage = ERROR;
        });

        builder.addCase(updateGiftRequest.pending, (state) => {
            state.gifts.stage = LOADING;
        });
        builder.addCase(updateGiftRequest.fulfilled, (state) => {
            state.gifts.stage = DATA;
        });
        builder.addCase(updateGiftRequest.rejected, (state) => {
            state.gifts.stage = ERROR;
        });

        builder.addCase(deleteGiftRequest.pending, (state) => {
            state.gifts.stage = LOADING;
        });
        builder.addCase(deleteGiftRequest.fulfilled, (state) => {
            state.gifts.stage = DATA;
        });
        builder.addCase(deleteGiftRequest.rejected, (state) => {
            state.gifts.stage = ERROR;
        });
    },
})
export const { setGiftsParams, setSearchValue, clearGiftsList } =
    giftsSlice.actions

export default giftsSlice.reducer
