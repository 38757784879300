import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { insight } from "app/api"
import { dataStages } from "app/helpers/enum"

const { LOADING, DATA, ERROR } = dataStages

export type RegisteredUsersType = {
  months: string
  users: unknown
}[]

export type InsightInitialState = {
  zheetaWalletBalance: {
    balance: {
      subscriptionSum: string | number
      withdrawalSum: string | number
      totalWalletSum: string | number
      affiliateSum: string | number
      profileBoostSum: string | number
    }
    stage: dataStages
  }
  customerTotalWalletBalance: {
    totalBalance: number
    stage: dataStages
  }
  usersGrowth: {
    registeredUsers: RegisteredUsersType
    stage: dataStages
    year: string
  }
  usersCountry: {
    countries: {
      countryCode: string
      goldCounts: number
      regularCounts: number
      silverCounts: number
      usersCount: number
    }[]
    stage: dataStages
  }
}

export const initialState: InsightInitialState = {
  zheetaWalletBalance: {
    balance: {
      subscriptionSum: 0,
      withdrawalSum: 0,
      totalWalletSum: 0,
      affiliateSum: 0,
      profileBoostSum: 0,
    },
    stage: LOADING,
  },
  customerTotalWalletBalance: {
    totalBalance: 0,
    stage: LOADING,
  },
  usersGrowth: {
    registeredUsers: [],
    stage: LOADING,
    year: String(new Date().getFullYear()),
  },
  usersCountry: {
    countries: [],
    stage: LOADING,
  },
}

export const zheetaWalletBalanceRequest = createAsyncThunk<
  InsightInitialState["zheetaWalletBalance"]["balance"],
  void
>("insight/zheeta-wallet-balance", async (_, { rejectWithValue }) => {
  try {
    const response = await insight.getZheetaWalletBalance()

    const data = response.data

    return {
      subscriptionSum: data?.subscriptionSum,
      totalWalletSum: data?.totalWalletSum,
      withdrawalSum: data?.withdrawalSum,
      affiliateSum: data?.affiliateSum,
      profileBoostSum: data?.profileBoostSum,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const totalWalletBalanceRequest = createAsyncThunk<
  InsightInitialState["customerTotalWalletBalance"]["totalBalance"],
  void
>("insight/total-wallet-balance", async (_, { rejectWithValue }) => {
  try {
    const response = await insight.getTotalWalletBalance()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const registeredUsersRequest = createAsyncThunk<
  RegisteredUsersType,
  string
>("insight/registered-users", async (values, { rejectWithValue }) => {
  try {
    const response = await insight.registeredUsers(values)

    const data = Object.entries(response.data.count)?.map((count) => ({
      months: count[0],
      users: count[1],
    }))

    return data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const usersCountryRequest = createAsyncThunk<
  InsightInitialState["usersCountry"]["countries"],
  void
>("insight/users-country", async (_, { rejectWithValue }) => {
  try {
    const response = await insight.usersCountry()

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const insightSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    setYear: (state, { payload }) => {
      return {
        ...state,
        usersGrowth: {
          ...state.usersGrowth,
          year: payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      zheetaWalletBalanceRequest.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          zheetaWalletBalance: {
            ...state.zheetaWalletBalance,
            balance: payload,
            stage: DATA,
          },
        }
      },
    )
    builder.addCase(zheetaWalletBalanceRequest.rejected, (state) => {
      state.zheetaWalletBalance.stage = ERROR
    })
    builder.addCase(
      totalWalletBalanceRequest.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          customerTotalWalletBalance: {
            ...state.customerTotalWalletBalance,
            totalBalance: payload,
            stage: DATA,
          },
        }
      },
    )
    builder.addCase(totalWalletBalanceRequest.rejected, (state) => {
      state.customerTotalWalletBalance.stage = ERROR
    })
    builder.addCase(registeredUsersRequest.pending, (state) => {
      state.usersGrowth.stage = LOADING
    })
    builder.addCase(registeredUsersRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        usersGrowth: {
          ...state.usersGrowth,
          registeredUsers: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(registeredUsersRequest.rejected, (state) => {
      state.usersGrowth.stage = ERROR
    })
    builder.addCase(usersCountryRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        usersCountry: {
          ...state.usersCountry,
          countries: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(usersCountryRequest.rejected, (state) => {
      state.usersCountry.stage = ERROR
    })
  },
})

export const { setYear } = insightSlice.actions

export default insightSlice.reducer
