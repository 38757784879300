import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { subscriptionFeatures } from "app/api"
import { dataStages } from "app/helpers/enum"
import { toast } from "react-hot-toast"

const { LOADING, DATA, ERROR } = dataStages

export type SubFeaturesType = {
  accessPeopleNearby: boolean
  allowCreateCommunity: boolean
  allowMessaging: boolean
  allowMultipleMediaInPost: boolean
  allowVideoMediaCategoryInPost: boolean
  allowWithdrawal: boolean
  fee: number
  id: number
  name: string
  noMatchesPerDay: number
  noOfPostPerDay: number
}

export type SubFeaturesInitialState = {
  subFeatures: {
    features: SubFeaturesType[]
    stage: dataStages
  }
  paymentTypes: {
    types: { id: number; name: string }[]
    stage: dataStages
  }
}

const initialState: SubFeaturesInitialState = {
  subFeatures: {
    features: [],
    stage: LOADING,
  },
  paymentTypes: {
    types: [],
    stage: LOADING,
  },
}

export const subFeaturesRequest = createAsyncThunk<
  SubFeaturesInitialState["subFeatures"]["features"],
  void
>("sub-feature/features", async (_, { rejectWithValue }) => {
  try {
    const response = await subscriptionFeatures.getSubFeatures()

    return response?.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const updateSubFeaturesRequest = createAsyncThunk<"", SubFeaturesType>(
  "sub-feature/update-features",
  async (values, { rejectWithValue }) => {
    try {
      await subscriptionFeatures.updateSubFeature(values)

      toast.success("Subscription feature updated successfully")
      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const paymentTypesRequest = createAsyncThunk<
  SubFeaturesInitialState["paymentTypes"]["types"],
  void
>("sub-feature/payment-types", async (_, { rejectWithValue }) => {
  try {
    const response = await subscriptionFeatures.getPaymentTypes()

    return response?.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const settingsSlice = createSlice({
  name: "sub-feature",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subFeaturesRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        subFeatures: {
          ...state.subFeatures,
          features: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(subFeaturesRequest.rejected, (state) => {
      state.subFeatures.stage = ERROR
    })
    builder.addCase(paymentTypesRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          types: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(paymentTypesRequest.rejected, (state, { payload }) => {
      state.paymentTypes.stage = ERROR
    })
  },
})

export default settingsSlice.reducer
