import { GiftsType } from "../GiftSlice";

export const createGiftFormData = (formData: GiftsType): FormData => {
    const formDataToSend = new FormData();
    formDataToSend.append('Image', formData.imageUrl);
    formDataToSend.append('Title', formData.title);
    formDataToSend.append('Amount', formData.amount.toString());

    return formDataToSend;
}

export const updateGiftFormData = (formData: GiftsType): FormData => {
    const formDataToSend = new FormData();
    formDataToSend.append('Id', formData.id ? formData.id : "");
    formDataToSend.append('Image', formData.imageUrl);
    formDataToSend.append('Title', formData.title);
    formDataToSend.append('Amount', formData.amount.toString());

    return formDataToSend;
}