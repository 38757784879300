import { lazy, Suspense } from "react"
import { createBrowserRouter } from "react-router-dom"
import Spinner from "app/components/Spinner/Spinner"
import NotFound from "app/components/NotFound/NotFound"
const LandingPage = lazy(() => import("features/LandingPage"))
const Insight = lazy(() => import("features/Insight"))
const Login = lazy(() => import("features/Auth/Login"))
const ForgotPassword = lazy(() => import("features/Auth/ForgotPassword"))
const ResetPassword = lazy(() => import("features/Auth/ResetPassword"))
const Users = lazy(() => import("features/Users"))
const Subscriptions = lazy(() => import("features/Subscriptions"))
const SubscriptionFeatures = lazy(() => import("features/SubscriptionFeatures"))
const GiftsManagement = lazy(() => import("features/GiftsManagement"))
const Layout = lazy(() => import("app/layout"))
const Staffs = lazy(() => import("features/Staffs"))
const PaymentHistory = lazy(() => import("features/PaymentHistory"))
const Transactions = lazy(() => import("features/Transactions"))
const ProfileBoost = lazy(() => import("features/ProfileBoost"))
const Settings = lazy(() => import("features/Settings"))
const Auth = lazy(() => import("features/Auth/Auth"))
const ProtectedRoute = lazy(() => import("./ProtectedRoute"))

// const LandingPage = () => {
//   return <div>LANDING PAGE</div>
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <LandingPage />
      </Suspense>
    ),
  },
  // {
  //   path: "/",
  //   element: <LandingPage />,
  // },
  {
    path: "admin",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <Layout />
      </Suspense>
    ),
    errorElement: <NotFound title="Something occurred!" />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "insight",
            element: <Insight />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "staffs",
            element: <Staffs />,
          },
          {
            path: "user-subscriptions",
            element: <Subscriptions />,
          },
          {
            path: "subscription-features",
            element: <SubscriptionFeatures />,
          },
          {
            path: "gifts-management",
            element: <GiftsManagement />,
          },
          {
            path: "transactions",
            element: <Transactions />,
          },
          {
            path: "payment-history",
            element: <PaymentHistory />,
          },
          {
            path: "profile-boost",
            element: <ProfileBoost />,
          },
          {
            path: "settings",
            element: <Settings />,
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: (
      <Suspense fallback={<Spinner height="100vh" />}>
        <Auth />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFound title="Page not found" />,
  },
])

export default router
