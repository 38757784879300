import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { transactions } from "app/api"
import { dataStages } from "app/helpers/enum"

const { LOADING, DATA, ERROR } = dataStages

export type TransactionsType = {
  userId: string
  title: string
  fromUsername: string
  amount: number
  transactionType: string
  lastModifiedDate: Date
  createdDate: Date
}

export type TransactionsInitialState = {
  transactions: {
    transactionList: {
      pages: {
        [key: number]: TransactionsType[]
      }
      totalRecords: number
    }
    transactionsParams: {
      PageNumber: number
      PageSize: number
    }
    searchValue: string
    stage: dataStages
  }
}

export const initialState: TransactionsInitialState = {
  transactions: {
    transactionList: {
      pages: {},
      totalRecords: 0,
    },
    transactionsParams: {
      PageNumber: 1,
      PageSize: 10,
    },
    searchValue: "",
    stage: LOADING,
  },
}

export const transactionsRequest = createAsyncThunk<
  {
    data: TransactionsInitialState["transactions"]["transactionList"]["pages"]
    totalRecords: TransactionsInitialState["transactions"]["transactionList"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; searchValue?: string }
>(
  "transactions",
  async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
    try {
      const response = await transactions.getTransactions(PageNumber, PageSize)

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactionsParams: (state, { payload }) => {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactionsParams: {
            ...state.transactions.transactionsParams,
            PageNumber: payload,
          },
        },
      }
    },
    setSearchValue: (state, { payload }) => {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          searchValue: payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(transactionsRequest.pending, (state, { payload }) => {
      state.transactions.stage = LOADING
    })
    builder.addCase(transactionsRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactionList: {
            pages: {
              ...state.transactions.transactionList.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(transactionsRequest.rejected, (state) => {
      state.transactions.stage = ERROR
    })
  },
})

export const { setTransactionsParams, setSearchValue } =
  transactionsSlice.actions

export default transactionsSlice.reducer
