import { toast } from "react-hot-toast"
import router from "app/route"

export const handleError = (statusCode: number, message: string) => {
  switch (statusCode) {
    case 400:
      toast.error(message || "Invalid request")
      break
    case 401:
      toast.error(message || "You are not allowed to do that!")
      break
    case 403:
      toast.error(message || "Unauthorized")
      router.navigate("/auth")
      break
    case 404:
      toast.error(message || "Data not found")
      break
    case 500:
      toast.error("Something went wrong, kindly try again.")
      break
    default:
      break
  }
}

export const handleResponse = (
  statusCode: number,
  successMessage: string,
  errorMessage: string,
) => {
  if (statusCode === 200) {
    toast.success(successMessage)
  } else {
    handleError(statusCode, errorMessage)
  }
}

export const formatMoney = (n: number) => {
  return new Intl.NumberFormat().format(n)
}

export const getPageFromCache = (
  state: {
    [key: number]: []
  },
  pageNumber: number,
) => state[pageNumber] || []

export const formatDate = (isoDateString: Date, showTime = true): string => {
  const date = new Date(isoDateString)

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  }

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }

  return new Intl.DateTimeFormat(
    "en-US",
    showTime ? { ...options, ...timeOptions } : options,
  ).format(date)
}
