import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { staffs, users } from "app/api"
import { toast } from "react-hot-toast"
import { dataStages } from "app/helpers/enum"
import { UsersType } from "features/Users/UsersSlice"

const { LOADING, DATA, ERROR } = dataStages

export type InviteStaffsType = {
  id?: string
  email: string
  phoneNumber: string
  password: string
  userName: string
  phoneCountryCode: string
}

export type StaffsInitialState = {
  staffs: {
    staffsList: {
      pages: {
        [key: number]: UsersType[]
      }
      totalRecords: number
    }
    staffsParams: {
      PageNumber: number
      PageSize: number
    }
    searchValue: string
    stage: dataStages
  }
}

export const initialState: StaffsInitialState = {
  staffs: {
    staffsList: {
      pages: {},
      totalRecords: 0,
    },
    staffsParams: {
      PageNumber: 1,
      PageSize: 10,
    },
    searchValue: "",
    stage: LOADING,
  },
}

export const staffsRequest = createAsyncThunk<
  {
    data: StaffsInitialState["staffs"]["staffsList"]["pages"]
    totalRecords: StaffsInitialState["staffs"]["staffsList"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; searchValue?: string }
>(
  "staff/staffs",
  async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
    try {
      const response = searchValue
        ? await users.usersSearch(PageNumber, PageSize, searchValue)
        : await staffs.getStaffs(PageNumber, PageSize)

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const inviteStaffRequest = createAsyncThunk<"", InviteStaffsType>(
  "staff/invite",
  async (values, { rejectWithValue }) => {
    try {
      await staffs.invite(values)

      toast.success("Invite sent successfully")
      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateStaffRequest = createAsyncThunk<"", InviteStaffsType>(
  "staff/update",
  async (values, { rejectWithValue }) => {
    try {
      await staffs.updateStaff(values)

      toast.success("Staff updated successfully")
      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const staffsSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {
    setStaffsParams: (state, { payload }) => {
      return {
        ...state,
        staffs: {
          ...state.staffs,
          staffsParams: {
            ...state.staffs.staffsParams,
            PageNumber: payload,
          },
        },
      }
    },
    setSearchValue: (state, { payload }) => {
      return {
        ...state,
        staffs: {
          ...state.staffs,
          searchValue: payload,
        },
      }
    },
    clearStaffsList: (state) => {
      return {
        ...state,
        staffs: {
          ...state.staffs,
          searchValue: "",
          staffsList: {
            ...state.staffs.staffsList,
            pages: {},
          },
          staffsParams: {
            ...state.staffs.staffsParams,
            PageNumber: 1,
          },
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(staffsRequest.pending, (state) => {
      state.staffs.stage = LOADING
    })
    builder.addCase(staffsRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        staffs: {
          ...state.staffs,
          staffsList: {
            pages: {
              ...state.staffs.staffsList.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(staffsRequest.rejected, (state) => {
      state.staffs.stage = ERROR
    })
  },
})

export const { setStaffsParams, setSearchValue, clearStaffsList } =
  staffsSlice.actions

export default staffsSlice.reducer
