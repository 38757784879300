import { FC } from "react"
import { ButtonProps } from "./ButtonProps"
import "./Button.scss"

const Button: FC<ButtonProps> = ({
  type = "button",
  text,
  rightIcon,
  classType,
  otherClassName,
  disabled,
  onClick,
  leftIcon,
  loading,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn ${classType} ${otherClassName ? otherClassName : ""}`}
      disabled={disabled}
      data-testid="button"
    >
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          {leftIcon && <>{leftIcon}</>} {text && <span>{text}</span>}
          {rightIcon && <>{rightIcon}</>}
        </>
      )}
    </button>
  )
}

export default Button
